import React from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { LayoutContext } from 'frontastic/provider/layout';
import { ButtonProps } from '../ampliance-cta-list-wrapper';
import AmplienceButton from '../amplience-button';
import styles from './amplience-text-component.module.scss';
export interface AmplienceTextComponentProps {
  content: {
    backgroundColour?: string;
    padding: {
      top: { mobile: number; desktop: number };
      right: { mobile: number; desktop: number };
      left: { mobile: number; desktop: number };
      bottom: { mobile: number; desktop: number };
    };
    sections: Section[];
  };
  _meta?: any;
  hideTextBody?: boolean;
}

type CTA = {
  text: string;
  url?: string;
  colour?: string;
  backgroundColour?: string;
};

type Section = {
  heading?: {
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    fontFamily: 'Libertinus Serif Display' | 'Proxima Nova' | 'Arial' | 'Helvetica' | 'Open Sans' | 'Manrope';
    textAlignment: 'left' | 'right' | 'center' | 'justify';
    textColour: string;
    text: string;
    fontSizeDesktop: number;
    fontSizeMobile: number;
  };
  ctaSection: {
    alignment: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-evenly' | 'space-around';
    ctas: ButtonProps[];
  };
  mainText: {
    text?: { data: any; type: string }[];
    fontFamily: 'Libertinus Serif Display' | 'Proxima Nova' | 'Arial' | 'Helvetica' | 'Open Sans' | 'Manrope';

    fontSizeDesktop: number;
    fontSizeMobile: number;
    columnNumber: number;
    textAlignment: 'left' | 'right' | 'center' | 'justify';
    textColour?: string;
    paragraphMargin: number;
    margin: {
      top: number;
      bottom: number;
    };
  };
};

const ctaAlignmentMap: Map<string, string> = new Map([
  ['start', 'flex-start'],
  ['end', 'flex-end'],
  ['center', 'center'],
  ['space between', 'space-between'],
  ['space evenly', 'space-evenly'],
  ['space around', 'space-around'],
]);

const AmplienceTextComponent: React.FC<AmplienceTextComponentProps> = ({ content, hideTextBody = false }) => {
  const dynamicStylesPlugin = (section) => (tree) => {
    tree.children.forEach((node) => {
      if (node.type === 'paragraph') {
        node.data = {
          hProperties: {
            style: {
              marginTop: section.mainText.paragraphMargin + 'px',
              marginBottom: section.mainText.paragraphMargin + 'px',
            },
          },
        };
      }
    });
  };

  const layout = React.useContext(LayoutContext);
  const Heading = ({ type, text }) => {
    const HeadingComponent = React.createElement(type, null, text);

    return <span className={classNames(styles[type], styles.heading)}>{HeadingComponent}</span>;
  };

  return (
    <div
      data-testid="amplience-text-component"
      style={{
        backgroundColor: content.backgroundColour,
        paddingBottom: layout.isMobile ? content.padding.bottom.mobile : content.padding.bottom.desktop,
        paddingTop: layout.isMobile ? content.padding.top.mobile : content.padding.top.desktop,
        paddingRight: layout.isMobile ? content.padding.right.mobile : content.padding.right.desktop,
        paddingLeft: layout.isMobile ? content.padding.left.mobile : content.padding.left.desktop,
      }}
    >
      {content.sections?.map((section, index) => {
        return (
          <div key={index}>
            {section.heading.text && (
              <div
                style={{
                  color: section.heading.textColour,
                  textAlign: section.heading.textAlignment,
                  fontSize: layout.isMobile ? section.heading.fontSizeMobile : section.heading.fontSizeDesktop,
                  fontFamily: section.heading.fontFamily,
                }}
                className={styles.heading}
              >
                <Heading type={section.heading.type} text={section.heading.text} />
              </div>
            )}
            {section.mainText?.text?.map((el, index) => {
              if (!hideTextBody)
                return (
                  <div
                    style={{
                      color: section.mainText.textColour,
                      textAlign: section.mainText.textAlignment,
                      fontSize: layout.isMobile ? section.mainText.fontSizeMobile : section.mainText.fontSizeDesktop,
                      columnCount: section.mainText.columnNumber,
                      marginTop: section.mainText.margin.top,
                      marginBottom: section.mainText.margin.bottom,
                      fontFamily: section.mainText.fontFamily,
                    }}
                    key={index}
                  >
                    <ReactMarkdown className={styles.mainText} remarkPlugins={[remarkGfm]}>
                      {el.data}
                    </ReactMarkdown>
                  </div>
                );
            })}

            {section.ctaSection?.ctas?.length && (
              <div
                className={styles.ctaContainer}
                style={{ justifyContent: ctaAlignmentMap.get(section.ctaSection.alignment) }}
              >
                {section.ctaSection.ctas.map((cta, index) => (
                  <AmplienceButton key={'TextComponent-' + cta.button?.link + '-' + index} content={cta.button} />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AmplienceTextComponent;
